body {
  background: #423939;
}

main {
  margin-top: 50vh;
  text-align: center;
}

main p {
  color: #d22929;
}